import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Button, Spinner } from 'reactstrap'

import PimsPatientSearchConfirm from './PimsPatientSearchConfirm'
import callFlask from '../../hasura/callFlask'
import { Case_cases_patient } from '../../hasura/graphQlQueries/types/Case'
import { INTACT, NEUTERED } from '../request-consult/config'
import { Input } from '../common/Input'
import { NotificationId, setNotificationAction } from '../../hasura/slices/notifications'
import { User_users } from '../../hasura/graphQlQueries/types/User'
import { VetspirePatient } from '../../interfaces/VetspirePatient'
import { getVetspirePimsOrganization } from '../../lib/userHelpers'
import { speciesFor, trackHotjarEvent } from '../../lib/helpers'
import { useDispatch } from 'react-redux'

const mapVetspirePatientValues: any = (patient: VetspirePatient) => {
  const weightUnit = patient.latestWeight?.unit || patient.preferredWeightUnit || 'lb'
  return {
    id: patient.id,
    display_name: patient.name,
    birthdate: moment(patient.birthDate).format('MM/DD/YYYY'),
    breed: patient.breed,
    neutered: patient.neutered ? NEUTERED : INTACT,
    gender: patient.sex === 'UNKNOWN' ? undefined : patient.sex.toLowerCase(),
    species: speciesFor(patient.species),
    weight: `${patient.latestWeight?.value}${weightUnit ? ` ${weightUnit}` : ''}`,
  }
}

// // 'Lemon' patient in Radimal Vetspire sandbox
// const TEST_VETSPIRE_PATIENT_ID = '39112'

interface Props {
  user: User_users
  update: (patient: VetspirePatient, notes?: string) => void
  patient: Case_cases_patient
}

enum Status {
  Success = 'Success',
  Failure = 'Failure',
}

interface VetspirePatientResult {
  patient: VetspirePatient
  notes?: string
}

export default function PimsPatientSearch(props: Props) {
  const dispatch = useDispatch()

  const [isNetworking, setIsNetworking] = useState(false)
  const [result, setResult] = useState<VetspirePatientResult | undefined>()
  const [searchValue, setSearchValue] = React.useState()
  const [status, setStatus] = useState<Status | undefined>()
  const [potentialPatients, setPotentialPatients] = useState<VetspirePatientResult[] | undefined>()

  const vetspire = getVetspirePimsOrganization(props.user)

  if (!vetspire) return null

  /*
    Effects
  */

  useEffect(() => {
    setStatus(undefined)
  }, [searchValue])

  useEffect(() => {
    searchByName()
  }, [props.patient])

  /*
    Methods
  */

  const confirm = (confirmed: boolean) => {
    trackHotjarEvent('searched_pims_patient')
    if (confirmed && result) {
      props.update(mapVetspirePatientValues(result.patient), result.notes || '')
      dispatch(setNotificationAction(NotificationId.PatientDataImported))
      setStatus(Status.Success)
    } else {
      setResult(undefined)
    }
  }

  const searchByName = async () => {
    const result: { success: number; patients: VetspirePatientResult[] } = await callFlask(
      `/integrations/vetspire/patient/search?name=${props.patient.display_name}&organization_id=${props.user.organization.id}`
    )
    setPotentialPatients(result.success ? result.patients : [])
  }

  const searchById = async () => {
    setIsNetworking(true)
    const result: { success: number; patient: VetspirePatient; notes?: string } = await callFlask(
      `/integrations/vetspire/patient?patient_id=${searchValue}&organization_id=${props.user.organization.id}`
    )
    setIsNetworking(false)
    if (result.success) {
      setResult(result)
    } else {
      setStatus(Status.Failure)
    }
  }

  const isSuccessful = status === Status.Success

  if (!Array.isArray(potentialPatients) || isSuccessful) {
    return null
  }

  return (
    <div className="pb-3 mb-3 border-bottom">
      {result && <PimsPatientSearchConfirm patient={result.patient} notes={result.notes || ''} confirm={confirm} />}

      <div className="d-flex align-items-center">
        {potentialPatients.length > 0 && (
          <div className="d-flex align-items-center">
            <div className="width-fit-content d-flex flex-column gap-10px">
              {potentialPatients.map((patient) => (
                <div className="d-flex align-items-center justify-content-between gap-30px">
                  <div>
                    <p className="text-m mb-0">{patient.patient.name}</p>
                    <p className="text-xs mb-0 text--gray5">{patient.patient.breed}</p>
                    <p className="text-xs mb-0 text--gray5">ID {patient.patient.id}</p>
                  </div>
                  <Button onClick={() => setResult(patient)} size="sm" color="success">
                    Confirm
                  </Button>
                </div>
              ))}
            </div>
            <p className="mb-0 mx-5">OR</p>
          </div>
        )}

        <div>
          <div className="d-flex align-items-end">
            <div>
              <p className="text-s m-0 bold">{potentialPatients.length ? 'Search by ID' : 'Import from Vetspire'}</p>
              <Input
                value={searchValue || ''}
                onChange={(e: any) => setSearchValue(e.target.value)}
                placeholder="Vetspire Patient ID"
                type="text"
              />
            </div>

            <div className="d-flex align-items-end">
              <Button
                onClick={searchById}
                disabled={!searchValue || status !== undefined || isNetworking}
                className="ml-2 width-100px"
                color="success"
              >
                Import
              </Button>

              {isNetworking && <Spinner color="success" className="ml-2" size="sm" />}
            </div>
          </div>

          {status && (
            <p className={`m-0 text-xs bold ${isSuccessful ? 'text--success' : 'text-danger'}`}>
              {isSuccessful ? 'Patient succesfully imported.' : 'Patient not found.'}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}
